
var api_base = "/api/v1/";
var na_icon = "<img style='height:30px;width:30px;margin-bottom:10px;margin-right:10px' src='/static/imgs/icons/not-avialable.png' />";
var av_icon = "<img style='height:30px;width:30px;margin-bottom:10px;margin-right:10px' src='/static/imgs/icons/avialable-icon.png?v=1.0' />";

$(document).ready(function () {

    if (document.getElementById('customModal') && checkCorporatePopupCookie()) {

        document.getElementById('customModal').style.display = 'block';

        document.getElementById('modalCloseBtn').onclick = function () {
            document.getElementById('customModal').style.display = 'none';
            setCorporatePopupCookie("corporatepopupcookie", "corp", 10000);
        };

        document.getElementById('closeFooterBtn').onclick = function () {
            document.getElementById('customModal').style.display = 'none';
            setCorporatePopupCookie("corporatepopupcookie", "corp", 10000);
        };

    }

    $(".formwidget").each(function () {

        //var id = $(this).attr('id');
        //$('#' + id).prependTo('#bizform-form-section');     

        $("#bizform-form-section div").removeClass("offset-lg-2");
        $("#bizform-form-section .col-lg-4").addClass("col-lg-6");
        $("#bizform-form-section div").removeClass("col-lg-4");

        $("#bizform-form-section .col-lg-8").addClass("col-lg-12");
        $("#bizform-form-section div").removeClass("col-lg-8");

        $(".inforline-form div").removeClass("offset-lg-2");
        $(".inforline-form .col-lg-4").addClass("col-lg-6");
        $(".inforline-form div").removeClass("col-lg-4");

        $(".inforline-form .col-lg-8").addClass("col-lg-12");
        $(".inforline-form div").removeClass("col-lg-8");

    });

    $(".main-nav.main-nav-mater li a").each(function () {

        if (window.location.href.indexOf($(this).prop("href")) > -1) {
            $(this).find("span").addClass("menu-overlay-d");
        }
        else {
            $(this).find("span").removeClass("menu-overlay-d");
        }

    });

    $('#icon-mail').on('click', function () {
        $('.inforline-form').stop(true, true).slideDown();
        $(this).hide();
        $('#icon-form-close').show();
    });

    $('#icon-form-close').on('click', function () {
        $('.inforline-form').stop(true, true).slideUp();
        $(this).hide();
        $('#icon-mail').show();
    });

    $('.orthopaedics-nav').find('#primary-nav').clone(true, true).insertBefore(".nav-wrapper #primary-nav");
    $('.nav-wrapper #primary-nav.ortho-nav .main-nav.main-nav-ortho').prepend('<li class="orthopaedics-home"> <a href="/services/orthopaedics" class="color-white"> <svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23"><defs><style>.a{fill:none;stroke:#fff;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-3.5 -2)"><path class="a" d="M4.5,10.35,14,3l9.5,7.35V21.9A2.106,2.106,0,0,1,21.389,24H6.611A2.106,2.106,0,0,1,4.5,21.9Z" transform="translate(0 0)"/><path class="a" d="M13.5,28.5V18h6.333V28.5" transform="translate(-2.667 -4.5)"/></g></svg>Orthopaedics </a></li>');
    $('.nav-wrapper #primary-nav.ortho-nav .main-nav.main-nav-mater').prepend('<li class="orthopaedics-home"> <a href="/services/maternity" class="color-white"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23"><defs><style>.a{fill:none;stroke:#fff;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-3.5 -2)"><path class="a" d="M4.5,10.35,14,3l9.5,7.35V21.9A2.106,2.106,0,0,1,21.389,24H6.611A2.106,2.106,0,0,1,4.5,21.9Z" transform="translate(0 0)"></path><path class="a" d="M13.5,28.5V18h6.333V28.5" transform="translate(-2.667 -4.5)"></path></g></svg>Maternity Home</a></li>');
    $('.nav-wrapper #primary-nav.ortho-nav .main-nav.main-nav-cardiac').prepend('<li class="orthopaedics-home"> <a href="/services/cardiac-services" class="color-white"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23"><defs><style>.a{fill:none;stroke:#fff;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-3.5 -2)"><path class="a" d="M4.5,10.35,14,3l9.5,7.35V21.9A2.106,2.106,0,0,1,21.389,24H6.611A2.106,2.106,0,0,1,4.5,21.9Z" transform="translate(0 0)"></path><path class="a" d="M13.5,28.5V18h6.333V28.5" transform="translate(-2.667 -4.5)"></path></g></svg>Cardiac Services</a></li>');
    $('.nav-wrapper #primary-nav.ortho-nav .main-nav.main-nav-elevate').prepend('<li class="orthopaedics-home"> <a href="/general-practitioners/elevate/on-demand-content" class="color-white"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="23" viewBox="0 0 21 23"><defs><style>.a{fill:none;stroke:#fff;stroke-linejoin:round;stroke-width:2px;}</style></defs><g transform="translate(-3.5 -2)"><path class="a" d="M4.5,10.35,14,3l9.5,7.35V21.9A2.106,2.106,0,0,1,21.389,24H6.611A2.106,2.106,0,0,1,4.5,21.9Z" transform="translate(0 0)"></path><path class="a" d="M13.5,28.5V18h6.333V28.5" transform="translate(-2.667 -4.5)"></path></g></svg>Elevate Program</a></li>');
    $('.nav-wrapper #primary-nav.ortho-nav .main-nav').prepend('<li class="mega-parent"> <a href="/"> SVPH Home </a></li>');

    $(".inforline.contact-box")
        .stickOnScroll({
            topOffset: 0,
            setParentOnStick: false,
        });

    if ($("#generic-page-div-form") && $("#generic-hero-inforline-form")) {

        $("#generic-page-div-form").prependTo("#generic-hero-inforline-form");

    }

    if ($("#drHospital")) {

        //var sydneyPath = "/services/cardiac-services?h=sydney";
        //var materPath = "/services/cardiac-services?h=mater";
        //var griffithPath = "/services/cardiac-services?h=griffith";
        //var brisbanePath = "/services/cardiac-services?h=brisbane";
        //var northsidePath = "/services/cardiac-services?h=northside";
        //var toowoombaPath = "/services/cardiac-services?h=toowoomba";
        //var eastmelbournePath = "/services/cardiac-services?h=east-melbourne";
        //var fitzroyPath = "/services/cardiac-services?h=fitzroy";
        //var kewPath = "/services/cardiac-services?h=kew";
        //var werribeePath = "/services/cardiac-services?h=werribee";

        //if (window.location.href.indexOf(sydneyPath) != -1) {
        //    $("#drHospital").val("SVPHSYD").trigger('change');
        //}
        //else if (window.location.href.indexOf(materPath) != -1) {
        //    $("#drHospital").val("MATER").trigger('change');
        //}
        //else if (window.location.href.indexOf(griffithPath) != -1) {
        //    $("#drHospital").val("SVPHGRIF").trigger('change');
        //}
        //else if (window.location.href.indexOf(brisbanePath) != -1) {
        //    $("#drHospital").val("SVPHBRIS").trigger('change');
        //}
        //else if (window.location.href.indexOf(northsidePath) != -1) {
        //    $("#drHospital").val("SVPHNORTHSIDE").trigger('change');
        //}
        //else if (window.location.href.indexOf(toowoombaPath) != -1) {
        //    $("#drHospital").val("SVPHTOOW").trigger('change');
        //}
        //else if (window.location.href.indexOf(eastmelbournePath) != -1) {
        //    $("#drHospital").val("SVPHESTMLB").trigger('change');
        //}
        //else if (window.location.href.indexOf(fitzroyPath) != -1) {
        //    $("#drHospital").val("SVPHFITZ").trigger('change');
        //}
        //else if (window.location.href.indexOf(kewPath) != -1) {
        //    $("#drHospital").val("SVPHKEW").trigger('change');
        //}
        //else if (window.location.href.indexOf(werribeePath) != -1) {
        //    $("#drHospital").val("SVPHWER").trigger('change');
        //}

        var servicefinderhospitalcookie = getServiceFinderCookieValue("cookie-service-finder-hospital");
        var servicefinderservicetypecookie = getServiceFinderCookieValue("cookie-service-finder-service-type");
        var servicefinderservicenamecookie = getServiceFinderCookieValue("cookie-service-finder-service-name");

        if ($("#drTreatment") && $("#drTreatment").val() != 'undefined' && $("#drTreatment").length) {
            onServiceTypeChange($("#drTreatment").val());

            if ($("#drTreatment").val() == "0") {
                createOrUpdateServiceFinderCookie("cookie-service-finder-service-name", "0", "-1");
                $("#drProcedure").val("0");
            }
        }

        if (servicefinderhospitalcookie && servicefinderhospitalcookie != "undefined") {

            if (hasOptionWithValue("#drHospital", servicefinderhospitalcookie)) {
                $("#drHospital").val(servicefinderhospitalcookie);
            }

        }

        if (servicefinderservicetypecookie && servicefinderservicetypecookie != "undefined") {

            if (hasOptionWithValue("#drTreatment", servicefinderservicetypecookie)) {
                $("#drTreatment").val(servicefinderservicetypecookie).trigger("change");
            }

        }

        if (servicefinderservicenamecookie && servicefinderservicenamecookie != "undefined") {

            setTimeout(
                function () {

                    if (hasOptionWithValue("#drProcedure", servicefinderservicenamecookie)) {
                        $("#drProcedure").val(servicefinderservicenamecookie).trigger("change");
                    }
                }, 500);


        }

    }

    var searchBox = document.getElementById('document-search-box');

    if (searchBox) {

        document.getElementById('document-search-box').addEventListener('keypress', function (event) {
            if (event.key === 'Enter') {
                event.preventDefault(); // Prevent the default form submission behavior
                onDocumentSearchServices(0);
            }
        });
    }

});

if (document.getElementsByClassName("current")[0] != undefined) {
    if (document.getElementsByClassName('current')[0].innerHTML.includes(', VIC') || document.getElementsByClassName('current')[0].innerHTML.includes(', NSW') || document.getElementsByClassName('current')[0].innerHTML.includes(', QLD')) {
        window.onload = localStorage.setItem("Hospital", document.getElementsByClassName('current')[0].innerText);
    }
    if ($("a[href^='/services?']")[0] !== undefined) {
        window.onload = localStorage.setItem("SeviceURl", $("a[href^='/services?']")[0].href);
    }
    if ($("a[href^='/services?']")[0] !== undefined) {
        window.onload = localStorage.setItem("SpecialistURl", $("a[href^='/specialists?']")[0].href);
    }
}


function OrthoContact(form) {

    if (form === 'ortho-form') {

        $('.inforline-form').stop(true, true).slideDown();
        $('#icon-mail').hide();
        $('#icon-form-close').show();
    }
}

function openContactForm() {

    $('.inforline-form').stop(true, true).slideDown();
    $('#icon-mail').hide();
    $('#icon-form-close').show();

}

function setServiceFilterDefaultControlState(issearch) {

    $("#p-title").html("");
    $("#p-body").html("No results found.");
    $("#p-body-2").html("");
    $("#p-body-2").css("display", "none");
    $("#full-article-link").css("display", "none");
    $("#search-service-result-list").css("display", "none");

    if (!issearch) {
        $("#finder-search-box").val("");
    }
}

function onTreatmentChange(id) {

    setServiceFilterDefaultControlState(false);

    $.ajax({
        type: "GET",
        url: api_base + "Service/GetProcedureFilterData",
        data: {
            treatmentId: id
        },
        contentType: "application/json",
        success: function (data) {

            $("#drProcedure").empty();

            for (var i = 0; i < data.length; i++) {

                var o = new Option(data[i].name, data[i].value);
                $("#drProcedure").append(o);

            }

            if (data.length > 0) {

                $("#p-title").html(data[0].title);
                $("#p-body").html(data[0].body);
                if (data[0].body2) {
                    $("#p-body-2").css("display", "block");
                    $("#p-body-2").html(data[0].body2);
                }
                if (data[0].fullArticleLink) {
                    $("#full-article-link").css("display", "inline-block");
                    $("#full-article-link").prop("href", data[0].fullArticleLink);
                }
            }


        }
    });

}

function onServiceTypeChange(id) {

    $("#next-steps-block").show();
    setServiceFilterDefaultControlState(false);

    var hospitalId = "";
    var servicefinderservicenamecookie = getServiceFinderCookieValue("cookie-service-finder-service-name");
    var hasValue = false;

    if ($("#drHospital")) {
        hospitalId = $("#drHospital").val();

        createOrUpdateServiceFinderCookie("cookie-service-finder-service-type", $("#drTreatment").val(), 1);

        if (servicefinderservicenamecookie == null || servicefinderservicenamecookie == "undefined") {
            createOrUpdateServiceFinderCookie("cookie-service-finder-service-name", "0", 1);
        }
    }

    $.ajax({
        type: "GET",
        url: api_base + "Service/GetServiceNamesFilterData",
        data: {
            serviceTypeId: id,
            hospitalCode: hospitalId
        },
        contentType: "application/json",
        success: function (data) {

            $("#drProcedure").empty();

            var def = new Option("Please select", "0");

            $("#drProcedure").append(def);

            for (var i = 0; i < data.length; i++) {

                var o = new Option(data[i].name, data[i].value);
                $("#drProcedure").append(o);

                if (data[i].value == servicefinderservicenamecookie)
                    hasValue = true;

            }

            if (hasValue) {
                $("#drProcedure").val(servicefinderservicenamecookie);
                $("#drProcedure").trigger("change");
            }

            //if (data.length > 0) {

            //    $("#p-title").html(data[0].title);
            //    $("#p-body").html(data[0].body);
            //    if (data[0].body2) {
            //        $("#p-body-2").css("display", "block");
            //        $("#p-body-2").html(data[0].body2);
            //    }
            //    if (data[0].fullArticleLink) {
            //        $("#full-article-link").css("display", "inline-block");
            //        $("#full-article-link").prop("href", data[0].fullArticleLink);
            //    }
            //}
            //else {                
            //    SetNoService($("#drTreatment :selected").text(), $("#drHospital :selected").text());
            //    GetTheHospitalNamesWithServiceOffer();
            //}

        }
    });

}

function getSelectedHospitalShortName(hospitalCode) {


    $.ajax({
        type: "GET",
        url: api_base + "Service/GetSelectedHospitalShortName",
        data: {
            hospitalCode: hospitalCode
        },
        contentType: "application/json",
        success: function (data) {

            if (data) {

                $(".btn-action-link").each(function (e) {

                    var href = $(this).attr("href"); // Get the href attribute value

                    // Check if href contains a certain value
                    if (href && href.indexOf("find-a-specialist") !== -1) {

                        // Split the URL into base and parameters
                        var parts = href.split('?');
                        var baseUrl = parts[0];
                        var params = parts.length > 1 ? parts[1].split('&') : [];

                        // Check if the 'h' parameter already exists
                        var hParamExists = false;
                        params.forEach(function (param, index) {
                            if (param.startsWith('hospital=')) {
                                // Replace the 'h' parameter value with the new dynamic value
                                params[index] = 'hospital=' + data;
                                hParamExists = true;
                            }
                        });

                        // If 'h' parameter doesn't exist, add it with the new dynamic value
                        if (!hParamExists && href.indexOf("/services/cardiac-services/find-a-specialist") == -1) {
                            params.push('hospital=' + data);
                        }

                        if (params.length > 0) {
                            // Reconstruct the URL with updated parameters
                            var newUrl = baseUrl + '?' + params.join('&');
                        }

                        // Update the href attribute value
                        $(this).attr("href", newUrl);
                    }

                });
            }

        }
    });

}

function onHospitalChange(hospitalcode, isFilterRequest, requestedService) {

    $("#next-steps-block").show();
    setServiceFilterDefaultControlState(false);

    var nodeid = $('#drHospital option:selected').attr('node-id');

    if (nodeid && nodeid != "undefined") {
        createOrUpdateServiceFinderCookie("service-finder-hospital-selection", nodeid, 1);
    }

    var serviceTypeId = "";

    $("#drHospital").val(hospitalcode);

    if ($("#drTreatment")) {
        serviceTypeId = $("#drTreatment").val();

        createOrUpdateServiceFinderCookie("cookie-service-finder-hospital", $("#drHospital").val(), 1);
        createOrUpdateServiceFinderCookie("cookie-service-finder-service-type", "0", 1);
        createOrUpdateServiceFinderCookie("cookie-service-finder-service-name", "0", 1);
    }

    getSelectedHospitalShortName(hospitalcode);

    $.ajax({
        type: "GET",
        url: api_base + "Service/GetServiceNamesFilterData",
        data: {
            serviceTypeId: serviceTypeId,
            hospitalCode: hospitalcode
        },
        contentType: "application/json",
        success: function (data) {

            $("#drProcedure").empty();

            var def = new Option("Please select", "0");
            $("#drProcedure").append(def);

            for (var i = 0; i < data.length; i++) {

                var o = new Option(data[i].name, data[i].value);
                $("#drProcedure").append(o);

            }

            //if (data.length > 0) {

            //    $("#p-title").html(data[0].title);
            //    $("#p-body").html(data[0].body);
            //    if (data[0].body2) {
            //        $("#p-body-2").css("display", "block");
            //        $("#p-body-2").html(data[0].body2);
            //    }
            //    if (data[0].fullArticleLink) {
            //        $("#full-article-link").css("display", "inline-block");
            //        $("#full-article-link").prop("href", data[0].fullArticleLink);
            //    }
            //}
            //else {
            //    //alert("no service type or name");
            //    SetNoService($("#drTreatment :selected").text(), $("#drHospital :selected").text());
            //    GetTheHospitalNamesWithServiceOffer();
            //}

            if (isFilterRequest) {
                $("#drProcedure").val(requestedService);
                onProcedureChange(requestedService);
            }
        }
    });

}

function onProcedureChange(id) {

    $("#next-steps-block").show();
    setServiceFilterDefaultControlState(false);

    var hospitalcode = null;
    var treatmentId = 0;

    if (id == "0") {
        $("#p-body").html("No results found.");
        return;
    }

    if ($("#drHospital"))
        hospitalcode = $("#drHospital").val();

    if ($("#drTreatment")) {
        treatmentId = $("#drTreatment").val();
        createOrUpdateServiceFinderCookie("cookie-service-finder-service-name", $("#drProcedure").val(), 1);
    }

    $.ajax({
        type: "GET",
        url: api_base + "Service/GetProcedureData",
        data: {
            procedureId: id,
            hospitalcode: hospitalcode,
            treatmentId: treatmentId
        },
        contentType: "application/json",
        success: function (data) {

            if (!data.title || data.title == "") {

                SetNoService($("#drProcedure :selected").text(), $("#drHospital :selected").text());

                var hasText = data.fullArticleLink || data.body;

                GetTheHospitalNamesWithServiceOffer(hasText);

                if (data.fullArticleLink) {

                    $("#full-article-link").css("display", "inline-block");
                    $("#full-article-link").prop("href", data.fullArticleLink);
                }

                if (data.body) {

                    $("#p-body").prepend(data.body + "<br/>");
                }

            }
            else {
                $("#p-title").html(data.title);
                $("#p-body").html(data.body);
                if (data.body2) {
                    $("#p-body-2").css("display", "block");
                    $("#p-body-2").html(data.body2);
                }
                if (data.fullArticleLink) {
                    $("#full-article-link").css("display", "inline-block");
                    $("#full-article-link").prop("href", data.fullArticleLink);
                }

            }
        }
    });

}

function GetTheHospitalNamesWithServiceOffer(hasText) {

    var serviceTypeId = "";
    $("#next-steps-block").hide();

    if ($("#drProcedure")) {
        serviceTypeId = $("#drProcedure").val();
    }

    $.ajax({
        type: "GET",
        url: api_base + "Service/GetTheHospitalNamesWithServiceOffer",
        data: {
            serviceTypeId: serviceTypeId,
            selectedHospital: $("#drHospital").val()
        },
        contentType: "application/json",
        success: function (data) {

            if (data.length > 0) {

                for (var i = 0; i < data.length; i++) {

                    if ($("#divselectlocation")) {
                        $("#spnpublicmsg").css("display", "none");
                    }

                    $("#p-body").append("<br/>" + av_icon + " <a href='javascript:void(0)' onclick=\"onHospitalChange('" + data[i].value + "',true, '" + $("#drProcedure").val() + "')\">" + data[i].text + "</a>");


                }
            }
            else {
                $("#divselectlocation").css("display", "none");

                if (hasText) {
                    $("#next-steps-block").hide();
                }
                else {
                    $("#next-steps-block").show();
                }
            }


        }
    });

}

function SetNoService(title, hospital, customMessage) {

    if (title != "Please select")
        $("#p-title").html(title);

    if (customMessage && customMessage != "") {
        $("#p-body").html(customMessage);
    }
    else {
        var message = na_icon + " This procedure is currently " + "<b>not performed at the " + hospital + "</b>" + "<div id='divselectlocation'><br/> Select one of the locations below to modify your search:</div>";

        if (hospital == "Please select") {
            message = "No results found.";
            $("#next-steps-block").show();
        } else {

            if (hospital.indexOf("VIC") > 0) {

                $("#full-article-link").css("display", "inline-block");
                message = message + "<span id='spnpublicmsg'><br/>" + av_icon + " Performed at <b>St Vincent's Public Hospital, Melbourne</b></span>";

            }
            else if (hospital.indexOf("NSW") > 0) {

                $("#full-article-link").css("display", "inline-block");
                message = message + "<span id='spnpublicmsg'><br/>" + av_icon + " Performed at <b>St Vincent's Public Hospital, Sydney</b></span>";

            }
        }

        $("#p-body").html(message);
    }
}

function onSearchServices(searchTitleAndDescription) {

    setServiceFilterDefaultControlState(true);

    $.ajax({
        type: "GET",
        url: api_base + "Service/GetServiceNamesSearchData",
        data: {
            searchTerm: $("#finder-search-box").val(),
            path: $("#hdnserviceitemspath").val(),
            searchTitleAndDescription: searchTitleAndDescription == 1 ? true : false
        },
        contentType: "application/json",

        success: function (data) {

            var resultlist = "";
            $("#search-service-result-list").css("display", "block");

            if (data.length > 0) {

                $("#p-body").html("");

                for (var i = 0; i < data.length; i++) {

                    var resultitem = "<div class='result-item'>";

                    resultitem = resultitem + "<div class='title-block pb-4'>  <p class='mb-0'>" + data[i].title + "</p> </div>";

                    resultitem = resultitem + "<div class='content-block pb-4'>" + data[i].body + "</div>";

                    if (data[i].fullArticleLink && data[i].fullArticleLink != '') {
                        resultitem = resultitem + "<a target='_self' style='display: inline-block' href='" + data[i].fullArticleLink + "' class='btn btn-md a-btn-primary btn-full text-block-button'> <span style='font-size:1.125rem !important'>Learn more</span> </a>";
                    }

                    resultitem = resultitem + "</div> ";

                    resultlist = resultlist + resultitem;
                }

            }
            //else {
            //    resultlist = "No results found.";
            //}

            $("#search-service-result-list").html(resultlist);

        }
    });

}

function filterSelection(c) {

    var x, i;
    x = document.getElementsByClassName("filter-div");

    if (c == "All") {

        $(".link-filter").each(function () {
            $(this).removeClass("link-filter-selected");
        });

        $(".link-filter." + c).addClass("link-filter-selected");

        c = "";

        for (i = 0; i < x.length; i++) {
            svRemoveClass(x[i], "show");
            if (x[i].className.indexOf(c) > -1) svAddClass(x[i], "show");
        }
    }
    else {

        for (i = 0; i < x.length; i++) {
            svRemoveClass(x[i], "show");
            if (x[i].className.indexOf(c) > -1) svAddClass(x[i], "show");
        }

        $(".link-filter").each(function () {
            $(this).removeClass("link-filter-selected");
        });

        $(".link-filter." + c).addClass("link-filter-selected");
    }
}

function svAddClass(element, name) {

    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");

    for (i = 0; i < arr2.length; i++) {
        if (arr1.indexOf(arr2[i]) == -1) { element.className += " " + arr2[i]; }
    }

}

function svRemoveClass(element, name) {

    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
        while (arr1.indexOf(arr2[i]) > -1) {
            arr1.splice(arr1.indexOf(arr2[i]), 1);
        }
    }
    element.className = arr1.join(" ");
}

function createOrUpdateServiceFinderCookie(cookieName, cookieValue, expirationDays) {

    var expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    var cookieString = encodeURIComponent(cookieName) + "=" + encodeURIComponent(cookieValue) + "; expires=" + expirationDate.toUTCString() + "; path=/";

    if (document.cookie.indexOf(cookieName) === -1) {
        // Cookie does not exist, create it
        document.cookie = cookieString;
    } else {
        // Cookie exists, update its value
        document.cookie = cookieString;
    }
}

function hasOptionWithValue(selectSelector, value) {
    return $(selectSelector).find('option').filter(function () {
        return $(this).val() == value;
    }).length > 0;
}

function getServiceFinderCookieValue(cookieName) {
    var name = encodeURIComponent(cookieName) + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i].trim();
        if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }
    return null;
}

/* popup start */


function OnFindOutMoreClick() {

    setCorporatePopupCookie("corporatepopupcookie", "corp", 10000);

    let a = document.createElement('a');
    a.target = '_blank';
    a.href = 'https://www.svha.org.au/about/our-strategy';
    a.click();
}

function setCorporatePopupCookie(cname, cvalue, exdays) {

    let cookie = getCorporatePopupCookie("corporatepopupcookie");

    if (cookie == "") {

        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

    }

}

function getCorporatePopupCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function checkCorporatePopupCookie() {
    let cookie = getCorporatePopupCookie("corporatepopupcookie");
    if (cookie != "") {
        return false;
    } else {
        return true;
    }
}

/* popup end */

/* Document Search widget */

function onDocumentSearchServices() {

    $("#p-body-noresult").css("display", "none");
    $("#loader").css("display", "block");
    $("#article-results-listing").html("");

    setServiceFilterDefaultControlState(true);

    $.ajax({
        type: "GET",
        url: api_base + "Service/GetArticleSearchData",
        data: {
            searchTerm: $("#document-search-box").val(),
            path: $("#hdnArticlesPath").val(),
            searchType: $("#hdnSearchType").val(),
            type: $("#hdntype").val(),
            id: $("#hdnid").val()
        },
        contentType: "application/json",

        success: function (data) {
            var resultlist = "";
            $("#article-search-service-result-list").css("display", "block");

            if (data.length > 0) {

                for (var i = 0; i < data.length; i++) {

                    var resultitem = createArticleBlockHTML(data[i].imageUrl, data[i].imageAlt, data[i].fullArticleLink, data[i].title, data[i].body, data[i].fullArticleLink2, data[i].value);

                    resultlist = resultlist + resultitem;

                }

                $("#article-results-listing").html('<div class="row">' + resultlist + "</div>");

            }
            else {
                $("#p-body-noresult").css("display", "block");
            }

            $("#loader").css("display", "none");

        }
    });

}

function createArticleBlockHTML(imageSrc, altText, linkHref, title, description, linkHref2, id) {
    const truncatedDescription = description && description.length > 100 ? description.substring(0, 100) + '...' : description;

    const findPresentationsLink = linkHref2 ? `
        <a href="${linkHref2}&id=${id}">
            <span><b>Find Presentations</b></span>
        </a>
        <br />` : '';

    return `
        <div class="col-12 col-md-3">
            <div class="news-tile article-block">
                <div class="img-block">
                    <img src="${imageSrc}" alt="${altText}">
                </div>
                <div class="txt-block">
                    <div class="link-wrap">
                        <a href="${linkHref}">
                            <span><b>${title}</b></span>
                        </a>
                        <br />
                        ${findPresentationsLink}
                        <p class="sub-txt">${truncatedDescription}</p>
                    </div>
                </div>
            </div>
        </div>
    `;
}


/* End Document Search widget */


/* Start Counter element */

const counterWrapper = document.getElementById('counter-element-wrapper');
document.addEventListener('DOMContentLoaded', function () {

    if (counterWrapper) {

        // Add counter element dynamically
        counterWrapper.innerHTML = `
    <span class="icon">
        <svg fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ahfeather ahfeather-clock" viewBox="0 0 24 24" id="clock" xmlns="http://www.w3.org/2000/svg" width="60">
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M12 6v6l4 2"></path>
        </svg>
    </span>
    <span class="counter-wrapper">
        <span class="counter">1</span>
    </span>
    `;

    }

    const counterElement = document.querySelector('.counter');
    const endValue = 343;
    let currentValue = 1;
    let hasAnimated = false;

    function startAnimation() {
        if (!hasAnimated) {
            hasAnimated = true;
            counterElement.classList.add('animate');
            animateCounter();
        }
    }

    function animateCounter() {
        if (currentValue <= endValue) {
            counterElement.textContent = currentValue;
            currentValue++;
            setTimeout(animateCounter, 10); // Adjust duration as needed
        }
    }

    function isInViewport(element) {

        if (counterWrapper) {
            const rect = element.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }

        return false;
    }

    if (counterWrapper) {
        window.addEventListener('scroll', function checkScroll() {
            if (isInViewport(counterWrapper)) {
                startAnimation();
                window.removeEventListener('scroll', checkScroll);
            }
        });

        // Check if the element is already in view on page load
        if (isInViewport(counterWrapper)) {
            startAnimation();
        }
    }
});

/* End counter element */