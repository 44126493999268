$(document).ready(function () {

    if ($('.ele-prg').length > 0) {

        loadEventsAndLearningContentBySpecialities();
        loadEventsAndLearningContentByHospitals();
        loadEventsBySearchText();
        loadEventsAndLearningContentByPresenter();
        loadPresentersBySearchText();
        $('#SelectedElevateSpeciality').change(function () {

            loadEventsAndLearningContentBySpecialities();

        });

        $('#SelectedHospital').change(function () {

            loadEventsAndLearningContentByHospitals();

        });

        $('input[type=radio][name=flexRadioDefault]').change(function () {

            loadEventsAndLearningContentBySpecialities();
            loadEventsAndLearningContentByHospitals();
            loadEventsAndLearningContentByPresenter();
            loadPresentersBySearchText();
        });

        // Trigger function on clicking the search icon
        $('#div-events-search').click(function () {
            loadEventsBySearchText();
        });

        $('#div-presenters-search').click(function () {
            loadPresentersBySearchText();
        });


        // Trigger function on pressing Enter key while the textbox is focused
        $('.ele-events input[name="SearchText"]').keypress(function (e) {
            if (e.which == 13) { // Enter key corresponds to keycode 13
                e.preventDefault(); // Prevent the default action (form submission)
                loadEventsBySearchText();
            }
        });

        $('.ele-presenters input[name="SearchText"]').keypress(function (e) {
            if (e.which == 13) { // Enter key corresponds to keycode 13
                e.preventDefault(); // Prevent the default action (form submission)
                loadPresentersBySearchText();
            }
        });

        $('.ele-events input[name="flexRadioDefault"]').change(function () {
            /* var filterType = $(this).val();*/
            loadEventsBySearchText();
        });

    }

});

function loadEventsAndLearningContentBySpecialities() {

    if ($(".ele-specs").length > 0) {

        $("#elevate-specialities-loader").css("display", "block");
        $('#elevate-event-and-content-area-id').html("");

        var titleText = "Content";

        var selectedSpeciality = $('#SelectedElevateSpeciality').val();
        var filterType = $('input[type=radio][name=flexRadioDefault]:checked').val();

        if (filterType == "events") {
            titleText = "Events";
        }
        else if (filterType == "presentations") {
            titleText = "Learning Content";
        }

        if (!selectedSpeciality || selectedSpeciality == null) {
            selectedSpeciality = 0;
            $("#elevate-specialities-search-header").html("All " + titleText);
        }
        else {
            $("#elevate-specialities-search-header").html("All " + titleText + " related to " + $('#SelectedElevateSpeciality :selected').text());
        }

        $.ajax({
            url: '/ElevateSpecialities/GetContentBySpecialityId',
            type: 'GET',
            data: { seletedSpeciality: selectedSpeciality, filterType: filterType },
            success: function (data) {
                $('#elevate-event-and-content-area-id').html(data);
                $("#elevate-specialities-loader").css("display", "none");
            },
            error: function () {
                //alert("An error occurred while loading content.");
            }
        });
    }
}

function loadEventsAndLearningContentByHospitals() {

    if ($(".ele-hospitals").length > 0) {

        $("#elevate-specialities-loader").css("display", "block");
        $('#elevate-event-and-content-area-id').html("");

        var titleText = "Content";

        var selectedHospital = $('#SelectedHospital').val();
        var filterType = $('input[type=radio][name=flexRadioDefault]:checked').val();

        if (filterType == "events") {
            titleText = "Events";
        }
        else if (filterType == "presentations") {
            titleText = "Learning Content";
        }

        if (!selectedHospital || selectedHospital == null) {
            selectedHospital = 0;
            $("#elevate-specialities-search-header").html("All " + titleText);
        }
        else {
            $("#elevate-specialities-search-header").html("All " + titleText + " related to " + $('#SelectedHospital :selected').text());
        }

        $.ajax({
            url: '/ElevateHospitals/GetContentByHospitalId',
            type: 'GET',
            data: { seletedSpeciality: 0, seletedHospital: selectedHospital, filterType: filterType },
            success: function (data) {
                $('#elevate-event-and-content-area-id').html(data);
                $("#elevate-specialities-loader").css("display", "none");
            },
            error: function () {
                //alert("An error occurred while loading content.");
            }
        });
    }
}

function loadEventsBySearchText() {

    if ($(".ele-events").length > 0) {

        $("#elevate-specialities-loader").css("display", "block");
        $('#elevate-event-and-content-area-id').html("");

        var titleText = "Events";

        var searchText = $('#SearchText').val();
        var filterType = $('input[type=radio][name=flexRadioDefault]:checked').val();

        if (filterType == "groupbyhospital") {
            titleText = "events grouped by hospital";
        }
        else if (filterType == "groupbyspeciality") {
            titleText = "events grouped by speciality";
        }

        if (!searchText || searchText == null) {
            $("#elevate-specialities-search-header").html("All " + titleText);
        }
        else {
            $("#elevate-specialities-search-header").html("All " + titleText + " related to " + searchText);
        }

        $.ajax({
            url: '/ElevateEvents/GetElevateEventsBySearchText',
            type: 'GET',
            data: { searchText: searchText, filterType: filterType },
            success: function (data) {
                $('#elevate-event-and-content-area-id').html(data);
                $("#elevate-specialities-loader").css("display", "none");
            },
            error: function () {
                //alert("An error occurred while loading content.");
            }
        });
    }
}

function loadEventsAndLearningContentByPresenter() {

    if ($(".ele-presenter").length > 0) {

        $("#elevate-specialities-loader").css("display", "block");
        $('#elevate-event-and-content-area-id').html("");

        var titleText = "Content";

        var presenter = getParameterByName('id');
        var filterType = $('input[type=radio][name=flexRadioDefault]:checked').val();

        if (filterType == "events") {
            titleText = "Events";
        }
        else if (filterType == "presentations") {
            titleText = "Learning Content";
        }

        $("#elevate-specialities-search-header").html("All " + titleText + " by " + $("#h1-doctor-name").html());

        if (!presenter || presenter == null) {
            presenter = 0;
        }

        $.ajax({
            url: '/ElevatePresenter/GetContentByPresenterId',
            type: 'GET',
            data: { presenter: presenter, filterType: filterType },
            success: function (data) {
                $('#elevate-event-and-content-area-id').html(data);
                $("#elevate-specialities-loader").css("display", "none");
            },
            error: function () {
                //alert("An error occurred while loading content.");
            }
        });
    }
}

function loadPresentersBySearchText() {

    if ($(".ele-presenters").length > 0) {

        $("#elevate-specialities-loader").css("display", "block");
        $('#elevate-event-and-content-area-id').html("");

        var titleText = "Presenters";

        var searchText = $('#SearchText').val();
        var filterType = $('input[type=radio][name=flexRadioDefault]:checked').val();

        if (filterType == "groupbyhospital") {
            titleText = "events grouped by hospital";
        }
        else if (filterType == "groupbyspeciality") {
            titleText = "events grouped by speciality";
        }

        if (!searchText || searchText == null) {
            $("#elevate-specialities-search-header").html("All " + titleText);
        }
        else {
            $("#elevate-specialities-search-header").html("All " + titleText + " related to " + searchText);
        }

        $.ajax({
            url: '/ElevatePresenters/GetContentByPresenters',
            type: 'GET',
            data: { searchText: searchText, filterType: filterType },
            success: function (data) {
                $('#elevate-event-and-content-area-id').html(data);
                $("#elevate-specialities-loader").css("display", "none");
            },
            error: function (e) {
                //alert("An error occurred while loading content.");
            }
        });
    }
}
function getParameterByName(name) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var url = window.location.href;
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    var results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}